<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">FINANCIAL </span>
      STATEMENT
    </PageHeader>
    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="getstudentfinanceRecord"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
              :rules="[(v) => !!v || 'Index number is required...']"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="loadStudent">
                    <span class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    Searching
                    <strong>Student...</strong>
                  </v-list-item-title>

                  <v-list-item-title v-else>
                    No
                    <strong>Student Found...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-scroll-y-transition>
      <v-row v-show="ifstudentFound">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row
                v-if="financeBasket.length > 0"
                align="center"
                justify="center"
              >
                <v-col>
                  <v-card>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>DATE</th>
                          <th>DESCRIPTION</th>
                          <th>ACADEMIC CALENDAR</th>
                          <th class="text-center">CREDIT</th>
                          <th class="text-center">DEBIT</th>
                          <th class="text-center">BALANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, key) in financeBasket" :key="key">
                          <td>{{ key + 1 }}</td>
                          <td>{{ new Date(row.created_at).toUTCString() }}</td>
                          <td>Fees</td>
                          <td>
                            {{ row.calendar.academic_calendar }}
                            {{ row.semester.semester }}
                          </td>
                          <td class="text-center">
                            {{ parseFloat(row.credit).toFixed(2) }}
                          </td>
                          <td class="text-center">
                            {{ parseFloat(row.debit).toFixed(2) }}
                          </td>
                          <td class="text-center">
                            {{ parseFloat(row.balance).toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-card v-else>
                <v-card-text> No Record(s) Found </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-scroll-y-transition>
  </v-container>
</template>
<script>
  import { defineComponent, reactive, ref, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";

  export default defineComponent({
    components: { PageHeader },

    setup() {
      const { getStudentCampus, getStudentFinance } = useActions([
        "getStudentCampus",
        "getStudentFinance",
      ]);

      const { getters_student_campus, user, getters_student_finance } =
        useGetters(["getters_student_campus", "user", "getters_student_finance"]);

      const searchStudentHeader = reactive({
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
      });

      const { searchStudent, studentcampusBasket, loadStudent } =
        toRefs(searchStudentHeader);

      let ifstudentFound = ref(false);
      let financeBasket = ref([]);

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          const infoBasket = {
            role: user.value.role,
            campus: user.value.campus,
          };
          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            });
        }
      });

      const getstudentfinanceRecord = (id) => {
        getStudentFinance(id).finally(() => {
          financeBasket.value = getters_student_finance.value;
          ifstudentFound.value = true;
        });
      };

      return {
        debouncesearchStudent,
        ...toRefs(searchStudentHeader),
        getstudentfinanceRecord,
        ifstudentFound,
        financeBasket,
      };
    },
  });
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.082);
  }
</style>